import { Menu, px } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import {
  IconColumnInsertRight,
  IconColumnRemove,
  IconRowInsertBottom,
  IconRowRemove,
  IconTable,
  IconTableOff,
  IconTablePlus,
  IconTableRow,
} from '@tabler/icons-react';
import { FC } from 'react';

import { CellShades } from './components';
import { TableControlProps } from './types';

export const TableControl: FC<TableControlProps> = ({ editor }) => (
  <Menu shadow="md">
    <Menu.Target>
      <RichTextEditor.Control aria-label="Table" title="Table">
        <IconTable stroke={1.5} size="1rem" />
      </RichTextEditor.Control>
    </Menu.Target>

    <Menu.Dropdown>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().insertTable({ rows: 1, cols: 3, withHeaderRow: true }).run()}
          aria-label="Insert table"
          title="Insert table"
        >
          <IconTablePlus stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().addRowAfter().run()}
          aria-label="Add row below"
          title="Add row below"
        >
          <IconRowInsertBottom stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().addColumnAfter().run()}
          aria-label="Add column right"
          title="Add column right"
        >
          <IconColumnInsertRight stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().deleteRow().run()}
          aria-label="Delete row"
          title="Delete row"
        >
          <IconRowRemove stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().deleteColumn().run()}
          aria-label="Delete column"
          title="Delete column"
        >
          <IconColumnRemove stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().toggleHeaderCell().run()}
          aria-label="Toggle header cell"
          title="Toggle header cell"
        >
          <IconTableRow stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().deleteTable().run()}
          aria-label="Delete table"
          title="Delete table"
        >
          <IconTableOff stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
        <CellShades editor={editor} />
      </RichTextEditor.ControlsGroup>
    </Menu.Dropdown>
  </Menu>
);
