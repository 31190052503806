import { Stack, Timeline } from '@mantine/core';
import { FC } from 'react';

import { useUsersManager } from '../../../../../../hooks';
import { RequestAvatar, RequesterItem, ResponderItem } from './components';
import { RequestProps } from './types';

export const Request: FC<RequestProps> = ({ card, request }) => {
  const { getUser } = useUsersManager();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { requester_id, responder_id } = request;

  const requester = getUser(requester_id);
  const responder = getUser(responder_id);

  const activeItems = request.response !== '' || request.withdrawn ? 1 : 0;

  return (
    <Stack>
      <Timeline ml="md" active={activeItems}>
        <Timeline.Item bullet={<RequestAvatar user={requester} isActive />}>
          <RequesterItem card={card} user={requester} request={request} />
        </Timeline.Item>
        <Timeline.Item bullet={<RequestAvatar user={responder} isActive={activeItems === 1} />}>
          <ResponderItem card={card} user={responder} request={request} />
        </Timeline.Item>
      </Timeline>
    </Stack>
  );
};
