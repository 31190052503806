import { ActionIcon, Menu, px, Tooltip } from '@mantine/core';
import { IconDots, IconFlame, IconX } from '@tabler/icons-react';
import { FC, MouseEvent } from 'react';

import { useAuthContext } from '../../../../../../../../../../contexts';
import { useCardsManager, useRequestsManager } from '../../../../../../../../../../hooks';
import { isNudgePossible, isWithdrawPossible } from '../../../../utils';
import { WEEK_FROM_NOW } from './constants';
import { RequestActionsProps } from './types';

export const RequestActions: FC<RequestActionsProps> = ({ card, request }) => {
  const { currentUserId } = useAuthContext();
  const { updateRequest } = useRequestsManager();
  const { nudgeCard, unSnoozeCard } = useCardsManager();

  const shouldShowWithdraw = isWithdrawPossible({ request, currentUserId });
  const shouldShowNudge = isNudgePossible({ card, request, currentUserId });

  const handleWithdraw = async (e: MouseEvent) => {
    e.stopPropagation();
    await updateRequest({ ...request, withdrawn: true });
  };

  const handleNudge = async (e: MouseEvent) => {
    e.stopPropagation();

    if (!currentUserId) {
      return;
    }

    await nudgeCard({
      card_id: card.id,
      user_id: request.responder_id,
      tuntil: WEEK_FROM_NOW,
    });
    try {
      await unSnoozeCard(card.id);
    } catch {
      //
    }
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Tooltip
          onClick={(e) => {
            e.stopPropagation();
          }}
          label="Request actions"
          withArrow
        >
          <ActionIcon variant="light" size="sm">
            <IconDots size={px('1rem')} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Request Actions</Menu.Label>
        {shouldShowWithdraw && (
          <Menu.Item leftSection={<IconX size={14} />} onClick={handleWithdraw}>
            Withdraw
          </Menu.Item>
        )}
        {shouldShowNudge && (
          <Menu.Item leftSection={<IconFlame size={14} />} onClick={handleNudge}>
            Nudge
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
