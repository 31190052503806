import { Button, Divider, Flex, Group, px, SegmentedControl, Stack, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowNarrowRight, IconX } from '@tabler/icons-react';
import { FC, useState } from 'react';

import { useCardsManager, useRequestsManager, useUsersManager } from '../../../../hooks';
import { isQuestion } from '../../../../utils';
import { DatePicker } from '../../../DatePicker';
import { UserAvatar } from '../../../UserAvatar';
import { UsersSelect } from '../../../UsersSelect';
import { SendBtn } from './components';
import { REQUEST_TYPES } from './const';
import { SendRequestFormProps } from './types';

export const SendRequestForm: FC<SendRequestFormProps> = ({ card, onHide }) => {
  const { id: cardId, description: cardDescription } = card;

  const { addRequest } = useRequestsManager();
  const { updateCard } = useCardsManager();
  const { currentUser, users } = useUsersManager();

  const [openedPrompt, { open, close }] = useDisclosure(false);

  const [currentRequestTarget, setCurrentRequestTarget] = useState<string>('');
  const [requestType, setRequestType] = useState('comment');
  const [requestText, setRequestText] = useState('');
  const [deadline, setDeadline] = useState<Date | null>(null);

  const handleSubmit = async (updatedCardDescription: string) => {
    const shouldOpenDescriptionPrompt = !openedPrompt && requestType === 'approval' && isQuestion(cardDescription);

    if (shouldOpenDescriptionPrompt) {
      open();
      return;
    }

    if (updatedCardDescription !== cardDescription) {
      await updateCard({ ...card, description: updatedCardDescription });
    }

    addRequest({ cardId, requestType, requestText, requestTarget: currentRequestTarget, deadline });

    close();
    setCurrentRequestTarget('');
    setRequestText('');
    setRequestType('comment');
    onHide?.();
  };

  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ cursor: 'default' }}
    >
      <Divider label={<span style={{ fontSize: 'larger' }}>Send a request</span>} labelPosition="left" />

      <Group justify="space-between" align="flex-end">
        <Stack>
          <Group>
            <UserAvatar piconly user_id={currentUser?.id} />
            <IconArrowNarrowRight />
            <UsersSelect
              users={users}
              currentRequestTarget={currentRequestTarget}
              setCurrentRequestTarget={setCurrentRequestTarget}
              width={350}
            />
            <SegmentedControl value={requestType} onChange={setRequestType} data={REQUEST_TYPES} />
          </Group>
          <Group wrap="nowrap">
            <Text c="gray">Request</Text>
            <TextInput
              pl="5"
              style={{ width: '100%' }}
              value={requestText}
              onChange={(e) => setRequestText(e.currentTarget.value)}
            />
          </Group>
          <Flex direction="row" align="center" gap="8">
            <Text c="gray" mr="10">
              Deadline <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>(optional)</span>{' '}
            </Text>
            <DatePicker onConfirm={setDeadline} title="Set a deadline" label="Set Deadline" />
          </Flex>
        </Stack>
      </Group>
      <Group>
        <Group justify="flex-end">
          <SendBtn
            cardDescription={cardDescription}
            isOpen={openedPrompt}
            isDisabled={requestText === '' || currentRequestTarget === undefined}
            handleOpen={open}
            handleSubmit={handleSubmit}
          />
          <Button leftSection={<IconX size={px('1rem')} />} variant="transparent" onClick={onHide}>
            Discard
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};
