import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, useCallback } from 'react';

import { useAddToMeetingContext, useDelegateCardContext } from '../../../../contexts';
import { useCardsManager } from '../../../../hooks';
import { getCardOwner } from '../../../../utils';
import { AddToStreamModal } from '../../../AddToStreamModal';
import { CardHeaderTimeAgo } from '../../../CardHeaderTimeAgo';
import { StateBtn } from '../../../StateBtn';
import { UserAvatar } from '../../../UserAvatar';
import { CardActions } from './components';
import { CardHeaderProps } from './types';

export const CardHeader: FC<CardHeaderProps> = ({ card, isMyStream, collectionId, onOpenCard }) => {
  const { updateCard, removeCollectionCard } = useCardsManager();
  const { openAddToMeetingModal } = useAddToMeetingContext();
  const { openDelegateCardModal } = useDelegateCardContext();

  const [pickStreamOpened, pickStreamHandlers] = useDisclosure(false);

  const setCardState = useCallback(
    async (state: string) => {
      const tmp = { ...card, state };
      await updateCard(tmp);
    },
    [card, updateCard]
  );

  return (
    <>
      <AddToStreamModal
        card={card}
        collectionId={collectionId}
        isOpened={pickStreamOpened}
        onClose={pickStreamHandlers.close}
      />
      <Group justify="apart" grow>
        <Group>
          <UserAvatar user_id={getCardOwner(card)} />
        </Group>
        <Group justify="flex-end">
          <Group>
            <CardHeaderTimeAgo card={card} />
            <StateBtn card={card} />
            <CardActions
              card={card}
              collectionId={collectionId}
              isRemovalDisabled={isMyStream || !collectionId}
              onOpenCard={onOpenCard}
              onArchiveCard={() => setCardState('archived')}
              onUnarchiveCard={() => setCardState('open')}
              onAddToStream={pickStreamHandlers.open}
              onAddToMeeting={() => openAddToMeetingModal(card)}
              onRemoveFromStream={() => collectionId && removeCollectionCard({ collectionId, cardId: card.id })}
              onDelegateCard={() => openDelegateCardModal(card)}
            />
          </Group>
        </Group>
      </Group>
    </>
  );
};
