import { useEffect, useState } from 'react';

import { useCollectionsManager } from '../../../../../hooks';

export const useSelectOptions = () => {
  const [data, setData] = useState<{ id: string; title: string }[]>([]);

  const { collections } = useCollectionsManager();

  useEffect(() => {
    const meetingSet = collections?.filter((c) => c.type === 'meeting').map(({ id, title }) => ({ id, title }));

    if (meetingSet) {
      setData(meetingSet);
    }
  }, [collections]);

  return { data, setData };
};
