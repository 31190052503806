import { Badge, Group } from '@mantine/core';
import { FC } from 'react';

import { RequestResponseTypes } from '../../../../../../../../../../data';
import { RESPONSE_TO_LABEL } from '../../constants';
import { TypeBadgeProps } from './types';

export const TypeBadge: FC<TypeBadgeProps> = ({ request }) => {
  if (request.response !== RequestResponseTypes.InProgress) {
    return null;
  }

  return (
    <Badge variant="light" color="blue" style={{ textTransform: 'uppercase' }}>
      <Group gap="4px">{RESPONSE_TO_LABEL[request.response]}</Group>
    </Badge>
  );
};
