import { Menu } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { IconAlignCenter } from '@tabler/icons-react';
import { FC } from 'react';

export const TextAlignControl: FC = () => (
  <Menu shadow="md">
    <Menu.Target>
      <RichTextEditor.Control aria-label="Align text" title="Align text">
        <IconAlignCenter stroke={1.5} size="1rem" />
      </RichTextEditor.Control>
    </Menu.Target>

    <Menu.Dropdown>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.AlignLeft />
        <RichTextEditor.AlignCenter />
        <RichTextEditor.AlignRight />
      </RichTextEditor.ControlsGroup>
    </Menu.Dropdown>
  </Menu>
);
