import { Text } from '@mantine/core';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { getLatestActivity } from '../../../../../../utils/getLatestActivity';
import { LastActivityProps } from './types';

export const LastActivity: FC<LastActivityProps> = ({ card, cardRequests }) => {
  const requests = cardRequests[card.id] || [];

  const latestActivity = getLatestActivity(requests);

  if (!latestActivity) {
    return null;
  }

  return (
    <Text fz="sm" c="grey">
      <ReactTimeAgo date={latestActivity} locale="en-US" />
    </Text>
  );
};
