import { Button, Combobox, Group, Input, InputBase, Modal, Stack, useCombobox } from '@mantine/core';
import { FC, useState } from 'react';

import { useCardsManager, useCollectionsManager } from '../../../../hooks';
import { useSelectOptions } from './hooks';
import { AddToMeetingModalProps } from './types';
import { filterOptions } from './utils';

export const AddToMeetingModal: FC<AddToMeetingModalProps> = ({ card, isOpened, onClose, onAddToMeeting }) => {
  const { addCollectionCard } = useCardsManager();
  const { addCollection } = useCollectionsManager();

  const [search, setSearch] = useState('');
  const [value, setValue] = useState<string | null>(null);

  const { data, setData } = useSelectOptions();

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const handleOptionSubmit = (val: string) => {
    if (val === '$create') {
      setData((current) => [...current.filter(({ id }) => id !== val), { id: val, title: `${search} (new)` }]);
    }
    setValue(val);

    combobox.closeDropdown();
  };

  const handleAddToMeeting = async () => {
    if (value === '$create') {
      const newOption = data.find(({ id }) => id === value);

      if (newOption == null) {
        return;
      }

      const title = newOption.title.replace(' (new)', '');
      const newCollection = await addCollection({
        title,
        description: '',
        type: 'meeting',
        header: 'https://nmcimages.s3.us-east-2.amazonaws.com/headers/header_81.png',
        icon: 'archive',
      });

      if (newCollection == null) {
        return;
      }

      await addCollectionCard({ collectionId: newCollection.id, cardId: card.id });
    } else if (value !== null) {
      await addCollectionCard({ collectionId: value, cardId: card.id });
    }

    onAddToMeeting?.();
    onClose();
  };

  const filteredOptions = filterOptions(data, search);
  const selectedItem = data.find((c) => c.id === value);

  return (
    <Modal opened={isOpened} onClose={onClose} title="Select Meeting">
      <Stack>
        <Combobox store={combobox} onOptionSubmit={handleOptionSubmit}>
          <Combobox.Target>
            <InputBase
              component="button"
              pointer
              rightSection={<Combobox.Chevron />}
              onClick={() => combobox.toggleDropdown()}
            >
              {selectedItem != null && selectedItem.title}
              {selectedItem == null && <Input.Placeholder>Select Meeting or Create New</Input.Placeholder>}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Search
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder="Search meetings or type to create new"
            />
            <Combobox.Options>
              {filteredOptions.map(({ id, title }) => (
                <Combobox.Option value={id} key={id}>
                  {title}
                </Combobox.Option>
              ))}
              {filteredOptions.length === 0 && (
                <>
                  <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
                  <Combobox.Empty>Nothing found</Combobox.Empty>
                </>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
        <Group>
          <Button disabled={value === null || value === ''} onClick={handleAddToMeeting}>
            Add to Meeting
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
