import { Card, Request } from '../../../../../../../data';
import { getCardOwner } from '../../../../../../../utils';
import { isRequestComplete } from './isRequestComplete';

interface IsNudgePossibleProps {
  card: Card;
  request: Request;
  currentUserId: string | null;
}

export const isNudgePossible = ({ card, request, currentUserId }: IsNudgePossibleProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { requester_id, withdrawn } = request;

  const isCardOwner = getCardOwner(card) === currentUserId;

  return !isRequestComplete(request) && !withdrawn && (isCardOwner || requester_id === currentUserId);
};
