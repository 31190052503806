import { RichTextEditor } from '@mantine/tiptap';
import { IconPhotoPlus } from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { useCardsManager } from '../../../../hooks';
import { Upload } from '../../../Upload';
import { ImageUploadProps } from './types';

export const ImageUpload: FC<ImageUploadProps> = ({ editor }) => {
  const { uploadImage } = useCardsManager();

  const handleFileChange = useCallback(
    async (file: File) => {
      try {
        const url = await uploadImage({ file });
        if (url) {
          editor?.chain().focus().setImage({ src: url }).run();
        }
      } catch (error) {
        //
      }
    },
    [editor, uploadImage]
  );

  return (
    <RichTextEditor.Control aria-label="Insert image" title="Insert image" variant="white" pos="relative">
      <IconPhotoPlus stroke={1.5} width={16} height={16} />
      <Upload onChange={handleFileChange} />
    </RichTextEditor.Control>
  );
};
