import { Badge, Group } from '@mantine/core';
import { IconCaretUpDown, IconChevronsRight, IconStepInto } from '@tabler/icons-react';
import { FC, useMemo } from 'react';

import { textByRequestType } from './constants';
import { RequestTypeBadgeProps } from './types';

export const RequestTypeBadge: FC<RequestTypeBadgeProps> = ({ request }) => {
  const icon = useMemo(() => {
    switch (request.request_type) {
      case 'approval':
        return <IconCaretUpDown size={16} />;
      case 'comment':
        return <IconStepInto size={16} />;
      case 'action':
        return <IconChevronsRight size={16} />;
      default:
        return null;
    }
  }, [request.request_type]);

  return (
    <Badge variant="light" color="blue" style={{ textTransform: 'uppercase' }}>
      <Group gap="4px">
        {icon}
        {textByRequestType[request.request_type]} request
      </Group>
    </Badge>
  );
};
