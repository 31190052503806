import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { Request, RequestResponseTypes, User } from '../../../../../../../../data';
import { useUsersManager } from '../../../../../../../../hooks';
import { AvatarRequestsGroup } from '../../../../../../../AvatarRequestsGroup';
import { CompletedApprovalsProps } from './types';

export const CompletedApprovals: FC<CompletedApprovalsProps> = ({ completedApprovals }) => {
  const { getUser } = useUsersManager();

  const { approvedRequests, deniedRequests } = completedApprovals.reduce<{
    approvedRequests: (Request & { user?: User })[];
    deniedRequests: (Request & { user?: User })[];
  }>(
    (requests, request) => {
      const requestWithUser = {
        ...request,
        user: getUser(request.responder_id),
      };

      if (request.response === RequestResponseTypes.Yes) {
        requests.approvedRequests.push(requestWithUser);
      } else {
        requests.deniedRequests.push(requestWithUser);
      }

      return requests;
    },
    { approvedRequests: [], deniedRequests: [] }
  );

  if (approvedRequests.length === 0 && deniedRequests.length === 0) {
    return null;
  }

  return (
    <>
      {approvedRequests.length > 0 && (
        <Group gap={8}>
          <Text>Approved requests </Text>
          <AvatarRequestsGroup requests={approvedRequests} />
        </Group>
      )}
      {deniedRequests.length > 0 && (
        <Group gap={8}>
          <Text>Denied requests </Text>
          <AvatarRequestsGroup requests={deniedRequests} />
        </Group>
      )}
    </>
  );
};
