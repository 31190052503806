import { IconUsersGroup } from '@tabler/icons-react';
import { FC } from 'react';

import { iconMap } from './data';
import { LabeledIconProps } from './types';

export const LabeledIcon: FC<LabeledIconProps> = ({ icon, className, stroke }) => {
  const IconComponent = iconMap[icon] || IconUsersGroup;

  return <IconComponent className={className} stroke={stroke} />;
};
