import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FC } from 'react';

import { useAuthContext } from '../../../../contexts';
import { LoginParams } from '../../../../contexts/AuthContext/types';

export const LoginForm: FC = () => {
  const { loginMutation, handleLogin } = useAuthContext();
  const { isPending, error } = loginMutation;

  const form = useForm<LoginParams>({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    validate: {
      email: (value) => (value.length < 2 ? 'Invalid email' : null),
      password: (value) => (value.length < 2 ? 'Invalid password' : null),
    },
  });

  const handleSubmit = async (values: LoginParams) => {
    handleLogin(values);
  };

  return (
    <Stack justify="center" h="100%">
      <Paper w="400" p="xl" ml="xl" shadow="xl" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isPending} loaderProps={{ children: 'Loading...' }} />

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Title ta="center" mb="md">
            Welcome back!
          </Title>
          <TextInput label="Email" placeholder="you@example.org" required {...form.getInputProps('email')} />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" {...form.getInputProps('rememberMe')} checked={form.values.rememberMe} />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button type="submit" fullWidth mt="xl">
            Sign in
          </Button>
          {!!error && (
            <Center mt={16}>
              <Text c="#E03131">
                Login/Password combination not recognized. Try again, or contact Andrew at{' '}
                <Anchor href="mailto:ao@onlychoices.net">ao@onlychoices.net</Anchor> for assistance.
              </Text>
            </Center>
          )}
        </form>
      </Paper>
    </Stack>
  );
};
