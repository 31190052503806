import { Request } from '../data';

export const getLatestActivity = (requests: Request[]) =>
  requests.reduce<number | null>((acc, request) => {
    if (!acc || request.tmodified > acc) {
      return request.tmodified;
    }

    return acc;
  }, null);
