import { Group, Stack, Text } from '@mantine/core';
import { IconPointFilled } from '@tabler/icons-react';
import { FC } from 'react';
import ReactTimeAgo from 'react-time-ago';

import { TimelineItemProps } from './types';

export const TimelineItem: FC<TimelineItemProps> = ({ user, time, rightSection, children }) => (
  <Stack gap="xs">
    <Group gap="xs">
      <Text>{user?.fullname}</Text>

      {time && (
        <>
          <IconPointFilled size={12} style={{ color: '#8a92a9' }} />
          <Text fz="sm" c="grey">
            <ReactTimeAgo date={time} locale="en-US" />
          </Text>
        </>
      )}

      {rightSection && (
        <>
          <IconPointFilled size={12} style={{ color: '#8a92a9' }} />
          {rightSection}
        </>
      )}
    </Group>
    {children}
  </Stack>
);
