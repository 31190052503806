import { Button, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, useEffect } from 'react';

import { DeadlineBadge } from '../../../../../../../../components/DeadlineBadge';
import { useAuthContext } from '../../../../../../../../contexts';
import { RequestResponseTypes } from '../../../../../../../../data';
import { isRequestComplete } from '../../utils';
import { TimelineItem } from '../TimelineItem';
import { RequestForm, RequestResponse, TypeBadge } from './components';
import { ResponderItemProps } from './types';

export const ResponderItem: FC<ResponderItemProps> = ({ card, user, request }) => {
  const [isEditResponseOpen, { toggle: toggleEditResponse, close: closeEditResponse }] = useDisclosure(false);

  const { currentUserId } = useAuthContext();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { withdrawn, response, tmodified, responder_id } = request;

  const isComplete = isRequestComplete(request) && !isEditResponseOpen;

  const isAllowedToEditResponse =
    [RequestResponseTypes.LetsMeet, RequestResponseTypes.Unable, RequestResponseTypes.InProgress].includes(response) &&
    responder_id === currentUserId;

  const isResponder = user?.id === currentUserId;
  const time = response !== '' ? tmodified : undefined;

  useEffect(() => {
    closeEditResponse();
  }, [closeEditResponse, request]);

  return (
    <TimelineItem
      user={user}
      time={time}
      rightSection={
        <>
          <DeadlineBadge isComplete={isComplete} tdeadline={request.tdeadline} />
          <TypeBadge request={request} />
          {isAllowedToEditResponse && (
            <Button onClick={toggleEditResponse} variant="outline" size="compact-sm">
              Edit Response
            </Button>
          )}
        </>
      }
    >
      {withdrawn && <Text>Withdrawn!</Text>}
      {!withdrawn && isComplete && <RequestResponse request={request} />}
      {!withdrawn && !isComplete && isResponder && <RequestForm card={card} request={request} />}
      {!withdrawn && !isComplete && !isResponder && <Text c="gray">awaiting response...</Text>}
    </TimelineItem>
  );
};
