import { Table, Text } from '@mantine/core';
import { FC, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StateLabel } from '../../../../components/StateLabel';
import { UserAvatar } from '../../../../components/UserAvatar';
import { Card } from '../../../../data';
import { getCardRoute } from '../../../../utils';
import { useLayout } from '../../hooks';
import { LayoutTypes } from '../../types';
import { Approvals, Deadline, LastActivity, OpenRequests } from './components';
import { StreamReportProps } from './types';

export const StreamReport: FC<StreamReportProps> = ({ group, streamCategories, cardRequests }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();

  const onOpenCard = (card: Card) => {
    if (!accountId) {
      return;
    }
    navigate(getCardRoute(accountId, card.id));
  };

  const { columnGroups, columnData } = useLayout({
    streamCategories,
    layoutField: LayoutTypes.Table,
    group,
  });

  return (
    <Table striped highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Card Title</Table.Th>
          <Table.Th>Waiting for</Table.Th>
          <Table.Th>Approvals</Table.Th>
          {group !== 'creator' && <Table.Th>Owner</Table.Th>}
          {group !== 'state' && <Table.Th>State</Table.Th>}
          <Table.Th>Next Deadline</Table.Th>
          <Table.Th>Last Activity</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {columnGroups.map((columnGroup) => (
          <Fragment key={columnGroup}>
            <Table.Tr key={columnGroup} style={{ backgroundColor: 'var(--mantine-color-blue-light)' }}>
              <Table.Td>
                {group === 'creator' && <UserAvatar user_id={columnGroup} />}
                {group !== 'creator' && <StateLabel status={columnGroup} />}
              </Table.Td>
              <Table.Td />
              <Table.Td />
              <Table.Td />
              <Table.Td />
              <Table.Td />
            </Table.Tr>
            {columnData[columnGroup].map((card: Card) => (
              <Table.Tr key={card.id}>
                <Table.Td>
                  <Text style={{ cursor: 'pointer' }} onClick={() => onOpenCard(card)}>
                    {card.description}
                  </Text>
                </Table.Td>
                <Table.Td align="center">
                  <OpenRequests card={card} cardRequests={cardRequests} />
                </Table.Td>
                <Table.Td align="center">
                  <Approvals card={card} cardRequests={cardRequests} />
                </Table.Td>
                {group !== 'creator' && (
                  <Table.Td>
                    <UserAvatar piconly user_id={card.creator_id} />
                  </Table.Td>
                )}
                {group !== 'state' && (
                  <Table.Td>
                    <StateLabel status={card.state} />
                  </Table.Td>
                )}
                <Table.Td>
                  <Deadline card={card} cardRequests={cardRequests} />
                </Table.Td>
                <Table.Td>
                  <LastActivity card={card} cardRequests={cardRequests} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Fragment>
        ))}
      </Table.Tbody>
    </Table>
  );
};
