import { RichTextEditor } from '@mantine/tiptap';
import { BubbleMenu } from '@tiptap/react';
import { FC } from 'react';

import {
  ControlButtons,
  HeadingIcon,
  ImageUpload,
  TableControl,
  TemplatesControl,
  TextAlignControl,
} from './components';
import { EditorProps } from './types';

export const Editor: FC<EditorProps> = ({ editor, isDisabled, onClear, onShowHistory, onSave, isLocked = false }) => (
  <RichTextEditor
    editor={editor}
    labels={{
      h3ControlLabel: 'Header',
    }}
  >
    <RichTextEditor.Toolbar sticky>
      {!isLocked && (
        <>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H3 icon={HeadingIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.OrderedList />
            <RichTextEditor.BulletList />
            <RichTextEditor.TaskList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <ImageUpload editor={editor} />
            <RichTextEditor.Link />
            <RichTextEditor.Hr />
          </RichTextEditor.ControlsGroup>

          <TextAlignControl />
          <RichTextEditor.ControlsGroup>
            <TableControl editor={editor} />
            <TemplatesControl editor={editor} />
          </RichTextEditor.ControlsGroup>
        </>
      )}

      {onSave && (
        <ControlButtons onClear={onClear} isDisabled={isDisabled} onShowHistory={onShowHistory} onSave={onSave} />
      )}
    </RichTextEditor.Toolbar>
    <div>
      {editor && (
        <BubbleMenu editor={editor}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>
        </BubbleMenu>
      )}

      <RichTextEditor.Content />
    </div>
  </RichTextEditor>
);
