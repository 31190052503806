import { getTaskListExtension, Link } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TipTapTaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor as useTiptapEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useState } from 'react';
import ImageResize from 'tiptap-extension-resize-image';

import { TableCell } from '../../components/Editor/components/TableControl/components';
import { UseEditorProps, UseEditorResponse } from './types';

export const useEditor = ({ content }: UseEditorProps): UseEditorResponse => {
  const [dirtyBody, setDirtyBody] = useState(false);

  const editor = useTiptapEditor({
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
      ImageResize.configure({
        HTMLAttributes: {
          width: '600px',
        },
      }),
      Underline,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: 'https',
        validate: (href) => /^https?:\/\//.test(href),
      }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'test-item',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate: () => {
      setDirtyBody(true);
    },
  });

  const onClear = () => {
    editor?.commands.setContent(content ?? '');
    setDirtyBody(false);
  };

  const onSubmit = () => {
    setDirtyBody(false);
  };

  return { editor, dirtyBody, onClear, onSubmit };
};
