import { Request, User } from '../../../data';
import { useRequestsManager } from '../../useRequestsManager';
import { useUsersManager } from '../../useUsersManager';

export const useOpenRequests = (cardId: string) => {
  const { getRequestsForCard } = useRequestsManager();
  const { getUser } = useUsersManager();

  const requests = getRequestsForCard(cardId);

  return requests.reduce<(Request & { user?: User })[]>((acc, request) => {
    if (request.response === '' && !request.withdrawn) {
      const requestWithUser = {
        ...request,
        user: getUser(request.requester_id),
      };
      acc.push(requestWithUser);
    }
    return acc;
  }, []);
};
