import { Button, Group } from '@mantine/core';
import { FC, useCallback } from 'react';

import { Request, RequestResponseTypes, RequestTypes } from '../../../../../../../../../../../../data';
import { useRequestsManager } from '../../../../../../../../../../../../hooks';
import { ActionResponse, ResponseToLabel } from './constants';

interface RequestButtonsProps {
  request: Request;
  comment: string;
}

export const RequestButtons: FC<RequestButtonsProps> = ({ request, comment }) => {
  const { updateRequest } = useRequestsManager();

  const saveResponse = useCallback(
    async (responseComment: string, value: RequestResponseTypes) => {
      const tmp = { ...request, comment: responseComment, response: value };
      await updateRequest(tmp);
    },
    [updateRequest, request]
  );

  if (request.request_type === RequestTypes.Comment) {
    return (
      <Group>
        <Button
          onClick={() => {
            saveResponse(comment, RequestResponseTypes.Done);
          }}
        >
          Send
        </Button>
      </Group>
    );
  }

  return (
    <Group>
      {ActionResponse.map((response) => (
        <Button
          key={response}
          onClick={() => {
            saveResponse(comment, response);
          }}
        >
          {ResponseToLabel[response]}
        </Button>
      ))}
    </Group>
  );
};
