import { ActionIcon, px, Tooltip } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import { FC, MouseEvent, useCallback } from 'react';

import { useFavorites } from '../../../../hooks';
import { FavoriteBtnProps } from './types';

export const FavoriteBtn: FC<FavoriteBtnProps> = ({ cardId }) => {
  const { isCardFavorite, handleToggleFavoriteCard } = useFavorites();

  const isFavorite = isCardFavorite(cardId);

  const onClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      handleToggleFavoriteCard(cardId);
    },
    [cardId, handleToggleFavoriteCard]
  );

  return (
    <Tooltip label="Favorite" withArrow>
      <ActionIcon variant="outline" radius="xl" onClick={onClick}>
        {isFavorite ? <IconStarFilled size={px('1rem')} /> : <IconStar size={px('1rem')} />}
      </ActionIcon>
    </Tooltip>
  );
};
