import { Box, Button, Flex, Group, Modal, Stack, Title } from '@mantine/core';
import { getTaskListExtension, Link } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TipTapTaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ImageResize from 'tiptap-extension-resize-image';

import { VersionItem } from './components';
import { VersioningModalProps } from './types';
import { getVersionName } from './utils';
import classNames from './VersioningModal.module.css';

export const VersioningModal: FC<VersioningModalProps> = ({ editor, provider, isOpen, onClose, isCompleted }) => {
  const versions = editor?.storage.collabHistory.versions;

  const [currentVersionId, setCurrentVersionId] = useState<number | null>(null);
  const isCurrentVersion = versions && versions.length > 0 ? currentVersionId === versions.at(-1)?.version : false;

  const previewEditor = useEditor({
    editable: false,
    content: '',
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
      ImageResize.configure({
        HTMLAttributes: {
          width: '600px',
        },
      }),
      Underline,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: 'https',
        validate: (href) => /^https?:\/\//.test(href),
      }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'test-item',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
  });

  const reversedVersions = useMemo(() => versions.slice().reverse(), [versions]);
  const versionData = useMemo(() => {
    if (!versions.length) {
      return null;
    }

    return versions.find((v: any) => v.version === currentVersionId);
  }, [currentVersionId, versions]);

  const handleVersionChange = useCallback(
    (newVersion: number) => {
      setCurrentVersionId(newVersion);

      provider.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: newVersion,
        })
      );
    },
    [provider]
  );

  const onOpenChange = useCallback(() => {
    onClose();
    setCurrentVersionId(null);
    previewEditor?.commands.clearContent();
  }, [onClose, previewEditor]);

  const handleRevert = useCallback(() => {
    if (currentVersionId === null || !versionData) {
      return;
    }

    editor?.commands.revertToVersion(currentVersionId);
    onClose();
  }, [currentVersionId, editor?.commands, onClose, versionData]);

  useEffect(() => {
    if (isOpen && currentVersionId === null && versions.length > 0) {
      const initialVersion = versions.at(-1)?.version;

      if (!initialVersion && initialVersion !== 0) {
        return;
      }

      setCurrentVersionId(initialVersion);

      provider?.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: initialVersion,
        })
      );
    }
  }, [currentVersionId, isOpen, provider, versions]);

  useEffect(() => {
    if (!isOpen) {
      return () => {};
    }

    const unbindContentWatcher = watchPreviewContent(provider, (content) => {
      if (previewEditor) {
        previewEditor.commands.setContent(content);
      }
    });

    return () => {
      unbindContentWatcher();
    };
  }, [isOpen, provider, previewEditor]);

  const shouldDisableRestore = !versionData || isCurrentVersion || isCompleted;

  return (
    <Modal opened={isOpen} onClose={onOpenChange} size="55rem" withCloseButton={false} padding={0}>
      <Flex direction="row" align="stretch" h={700}>
        <Box className={classNames.main}>
          <EditorContent editor={previewEditor} />
        </Box>
        <Box className={classNames.sidebar}>
          <Box className={classNames.title}>
            <Title order={5} mb={16}>
              History ({reversedVersions.length} versions)
            </Title>
          </Box>
          <Stack gap={6} className={classNames.versions} flex={1}>
            {reversedVersions.map((v: any) => (
              <VersionItem
                date={v.date}
                number={v.version}
                title={getVersionName(v)}
                onClick={() => handleVersionChange(v.version)}
                isActive={currentVersionId === v.version}
                key={`version_item_${v.version}`}
              />
            ))}
          </Stack>
          <Group gap={8} className={classNames.buttons}>
            <Button variant="outline" type="button" onClick={onClose}>
              Close
            </Button>
            <Button type="button" disabled={shouldDisableRestore} onClick={handleRevert}>
              Restore
            </Button>
          </Group>
        </Box>
      </Flex>
    </Modal>
  );
};
