import { TiptapCollabProvider } from '@hocuspocus/provider';
import { getTaskListExtension, Link } from '@mantine/tiptap';
import { useQuery } from '@tanstack/react-query';
import Collaboration from '@tiptap/extension-collaboration';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TipTapTaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor as useTiptapEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CollaborationHistory from '@tiptap-pro/extension-collaboration-history';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageResize from 'tiptap-extension-resize-image';
import * as Y from 'yjs';

import { TableCell } from '../../components/Editor/components/TableControl/components';
import { useAuthContext } from '../../contexts/AuthContext';
import { authService } from '../../services';
import { useUsersManager } from '../useUsersManager';
import { UseAdvancedEditorProps, UseAdvancedEditorResponse } from './types';

export const useAdvancedEditor = ({ content }: UseAdvancedEditorProps): UseAdvancedEditorResponse => {
  const { currentUser } = useUsersManager();
  const { isAuthenticated } = useAuthContext();
  const { accountId, cardId } = useParams();
  const [dirtyBody, setDirtyBody] = useState(false);
  const doc = useMemo(() => new Y.Doc(), []);

  const { data: tipTapToken } = useQuery({
    queryKey: ['tiptapToken'],
    queryFn: authService.getTiptapToken,
    enabled: !!isAuthenticated,
    retry: false,
  });

  const provider = useMemo(
    () =>
      new TiptapCollabProvider({
        name: `${accountId}/${cardId}`,
        appId: process.env.REACT_APP_TIP_TAP_APP_ID!,
        token: tipTapToken!,
        document: doc,
        user: currentUser?.id,
      }),
    [accountId, cardId, currentUser?.id, doc, tipTapToken]
  );

  const editor = useTiptapEditor({
    extensions: [
      Table.configure({
        resizable: true,
      }),
      TableCell,
      TableHeader,
      TableRow,
      ImageResize.configure({
        HTMLAttributes: {
          width: '600px',
        },
      }),
      Underline,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: 'https',
        validate: (href) => /^https?:\/\//.test(href),
      }),
      getTaskListExtension(TipTapTaskList),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'test-item',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      StarterKit.configure({
        history: false,
      }),
      Collaboration.configure({
        document: doc,
      }),
      CollaborationHistory.configure({
        provider,
      }),
    ],
  });

  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        const currentContent = editor.getHTML();
        setDirtyBody(content !== currentContent);
      });
    }

    return () => {
      editor?.off('update');
    };
  }, [editor, content]);

  const onClear = () => {
    editor?.commands.setContent(content ?? '');
    setDirtyBody(false);
  };

  const onSubmit = useCallback(() => {
    editor?.commands.saveVersion(currentUser?.id);
    setDirtyBody(false);
  }, [editor, currentUser]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (editor?.storage.collabHistory.versioningEnabled) {
      editor.commands.toggleVersioning();
    }
  }, [editor, provider]);

  useEffect(() => {
    provider.on('synced', () => {
      editor?.commands.setContent(content ?? '');
    });

    return () => {
      provider.off('synced');
    };
  });

  return {
    editor,
    dirtyBody,
    onClear,
    onSubmit,
    provider,
    isSynced: provider.isSynced ?? false,
  };
};
