/*
CREATE TABLE tbl_request(
    id              uuid NOT NULL DEFAULT uuid_generate_v1() PRIMARY KEY,
    account_id      uuid REFERENCES tbl_account(id),
    requester_id    uuid REFERENCES tbl_user(id),
    responder_id    uuid REFERENCES tbl_user(id),
    card_id         uuid REFERENCES tbl_card(id),
    request_type    TEXT,
    title           TEXT,
    content         TEXT,
    choices         TEXT,
    response        TEXT,
    comment         TEXT,
    withdrawn       BOOLEAN DEFAULT FALSE,
    tdeadline       TIMESTAMP,
    is_deleted      BOOLEAN DEFAULT FALSE,
    tcreated        TIMESTAMP NOT NULL DEFAULT now(),
    tmodified       TIMESTAMP,
    tdeleted        TIMESTAMP
); */

import { RemoteObject } from './Interfaces';

export interface Request extends RemoteObject {
  readonly requester_id: string;
  responder_id: string;
  readonly card_id: string;
  request_type: RequestTypes;
  title: string;
  content: string;
  choices: string;
  response: RequestResponseTypes;
  comment: string;
  tdeadline: number | null;
  withdrawn: boolean;
}

export enum RequestTypes {
  Approval = 'approval',
  Comment = 'comment',
  Action = 'action',
}

export enum RequestResponseTypes {
  Yes = 'Yes',
  No = 'No',
  Done = 'Done',
  Unable = 'Unable',
  LetsMeet = 'LetsMeet',
  InProgress = 'InProgress',
  Empty = '',
}
