import { Group } from '@mantine/core';
import { IconCheck, IconClock, IconX } from '@tabler/icons-react';
import { FC, useMemo } from 'react';

import { RequestTypes } from '../../../../../../data';
import { useUsersManager } from '../../../../../../hooks';
import { AvatarsList } from './components';
import { ApprovalsProps } from './types';
import { getGroupedRequests } from './utils';

export const Approvals: FC<ApprovalsProps> = ({ card, cardRequests }) => {
  const { getUser } = useUsersManager();

  const approvalRequests = useMemo(
    () =>
      cardRequests[card.id]
        .filter(({ request_type }) => request_type === RequestTypes.Approval)
        .map((request) => ({
          ...request,
          user: getUser(request.responder_id),
        })),
    [card.id, cardRequests, getUser]
  );

  const { completedApprovalRequests, abortedApprovalRequests, pendingApprovalRequests } = useMemo(
    () => getGroupedRequests(approvalRequests),
    [approvalRequests]
  );

  return (
    <Group gap={8}>
      {completedApprovalRequests.length > 0 && (
        <AvatarsList color="teal" requests={completedApprovalRequests}>
          <IconCheck size={18} />
        </AvatarsList>
      )}
      {abortedApprovalRequests.length > 0 && (
        <AvatarsList color="red" requests={abortedApprovalRequests}>
          <IconX size={18} />
        </AvatarsList>
      )}
      {pendingApprovalRequests.length > 0 && (
        <AvatarsList color="gray" requests={pendingApprovalRequests}>
          <IconClock size={18} />
        </AvatarsList>
      )}
    </Group>
  );
};
