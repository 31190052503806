import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { authService } from '../../services';
import { AuthContext } from './context';
import { useAuthStateInStorage } from './hooks';
import { LoginParams } from './types';
import { setAuthHeaders } from './utils';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();

  const [shouldRememberMe, setShouldRememberMe] = useState(false);

  const { isAuthStateBeingInitialized, authState, setAuthStateInStorage } = useAuthStateInStorage(shouldRememberMe);
  const { currentUserId = null, account = null } = authState || {};

  const loginMutation = useMutation({
    mutationFn: authService.login,
    onSuccess: (response) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { user_id, token } = response;

      setAuthHeaders(token);
      setAuthStateInStorage({ currentUserId: user_id, refreshToken: token });
    },
  });

  const changePasswordMutation = useMutation({
    mutationFn: authService.changePassword,
  });

  const handleLogin = useCallback(
    ({ rememberMe, ...params }: LoginParams) => {
      setShouldRememberMe(rememberMe);
      loginMutation.mutate(params);
    },
    [loginMutation]
  );

  const handleLogout = useCallback(() => {
    setAuthStateInStorage(null);
    setAuthHeaders();

    queryClient.clear();
  }, [queryClient, setAuthStateInStorage]);

  const handleSelectAccount = useCallback(
    async (selectedAccountId: string) => {
      if (!currentUserId) {
        return;
      }

      const { token: accessToken } = await authService.getAccessToken(selectedAccountId, currentUserId);

      setAuthHeaders(accessToken);
      setAuthStateInStorage({ ...authState, account: selectedAccountId, accessToken });
    },
    [authState, currentUserId, setAuthStateInStorage]
  );

  const value = useMemo(
    () => ({
      isAuthStateBeingInitialized,
      isAuthenticated: Boolean(authState?.accessToken),
      account,
      currentUserId,
      loginMutation,
      handleLogin,
      handleSelectAccount,
      handleLogout,
      changePasswordMutation,
    }),
    [
      isAuthStateBeingInitialized,
      authState?.accessToken,
      account,
      currentUserId,
      loginMutation,
      handleLogin,
      handleSelectAccount,
      handleLogout,
      changePasswordMutation,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
