import { Stack, Textarea } from '@mantine/core';
import { FC, useState } from 'react';

import { Card, Request } from '../../../../../../../../../../data';
import { ApprovalButtons, RequestButtons } from './components';

interface RequestFormProps {
  card: Card;
  request: Request;
}

export const RequestForm: FC<RequestFormProps> = ({ card, request }) => {
  const [comment, setComment] = useState('');

  const shouldShowApprovalButtons = request.request_type === 'approval';

  return (
    <Stack>
      <Textarea
        label="Comment"
        value={comment}
        onChange={(e) => {
          setComment(e.target.value?.toString() || '');
        }}
        placeholder="Optional comment related to request"
      />
      {shouldShowApprovalButtons && <ApprovalButtons card={card} request={request} comment={comment} />}
      {!shouldShowApprovalButtons && <RequestButtons request={request} comment={comment} />}
    </Stack>
  );
};
