import { RequestResponseTypes } from '../../../../../../../../data';

export const RESPONSE_TO_LABEL: Record<RequestResponseTypes, string> = {
  [RequestResponseTypes.Yes]: 'Approved',
  [RequestResponseTypes.No]: 'Declined',
  [RequestResponseTypes.Done]: 'Done',
  [RequestResponseTypes.Unable]: 'Unable to do',
  [RequestResponseTypes.LetsMeet]: "Let's meet",
  [RequestResponseTypes.Empty]: 'Empty',
  [RequestResponseTypes.InProgress]: 'In progress',
};
