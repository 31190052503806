import { Box, Menu, px } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { IconPalette, IconPaletteOff } from '@tabler/icons-react';
import { FC } from 'react';

import classNames from './CellShades.module.css';
import { CellShadesProps } from './types';

export const CellShades: FC<CellShadesProps> = ({ editor }) => (
  <Menu shadow="md">
    <Menu.Target>
      <RichTextEditor.Control aria-label="Add cell shade" title="Add cell shade">
        <IconPalette stroke={1.5} size={px('1rem')} />
      </RichTextEditor.Control>
    </Menu.Target>

    <Menu.Dropdown>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#228be61a').run()}
          aria-label="Blue"
          title="Blue"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#228be61a' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#fad1f2').run()}
          aria-label="Pink"
          title="Pink"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#fad1f2' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#d2fad1').run()}
          aria-label="Green"
          title="Green"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#d2fad1' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#faf4d1').run()}
          aria-label="Yellow"
          title="Yellow"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#faf4d1' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#fad1d1').run()}
          aria-label="Red"
          title="Red"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#fad1d1' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#e6d1fa').run()}
          aria-label="Purple"
          title="Purple"
        >
          <Box className={classNames.color} style={{ backgroundColor: '#e6d1fa' }} />
        </RichTextEditor.Control>
        <RichTextEditor.Control
          onClick={() => editor?.chain().focus().setCellAttribute('backgroundColor', '#fffff').run()}
          aria-label="Clear cell shade"
          title="Clear cell shade"
        >
          <IconPaletteOff stroke={1.5} size={px('1rem')} />
        </RichTextEditor.Control>
      </RichTextEditor.ControlsGroup>
    </Menu.Dropdown>
  </Menu>
);
