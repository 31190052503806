import { Divider, Group, Paper, Stack, Text } from '@mantine/core';
import { FC, useState } from 'react';

import { RequestActions } from '../../../../components/RequestActions';
import { useCardsManager, useRequestsManager, useSetDocumentTitle } from '../../../../hooks';
import { EditableTitle, FullCardEditZone, FullCardHeader } from './components';
import { Request } from './components/Request';

interface FullCardProps {
  cardId: string;
}

export const FullCard: FC<FullCardProps> = ({ cardId }) => {
  const { getRequestsForCard } = useRequestsManager();
  const { getCard, updateCard } = useCardsManager();

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const card = getCard(cardId);
  const requests = getRequestsForCard(cardId);

  const handleSaveTitle = async (title: string) => {
    if (!card) {
      return;
    }

    const { id, document, tags, state } = card;
    await updateCard({ id, document, tags, state, description: title });

    setIsEditingTitle(false);
  };

  useSetDocumentTitle(card?.description || 'Card');

  if (!card || card.id === '') {
    return <Text>Loading...</Text>;
  }

  return (
    <Paper shadow="xs" p="md">
      <Stack>
        <FullCardHeader card={card} setIsEditingTitle={setIsEditingTitle} />
        <Group gap="8px">
          <EditableTitle
            title={card.description}
            isEditing={isEditingTitle}
            onSaveTitle={handleSaveTitle}
            onCancel={() => setIsEditingTitle(false)}
            onClick={() => setIsEditingTitle(true)}
          />
        </Group>
        <FullCardEditZone card={card} />
        <Stack>
          <RequestActions card={card} />
          <Divider />
          <Stack gap="42px">
            {requests.map((request) => (
              <Request card={card} key={request.id} request={request} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
