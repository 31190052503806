import { Avatar, Badge, Group, Tooltip } from '@mantine/core';
import { FC } from 'react';

import { AvatarsListProps } from './types';

export const AvatarsList: FC<AvatarsListProps> = ({ color, requests, children }) => (
  <Badge color={color} size="xl" px={4}>
    <Group gap={6}>
      {children}
      <Tooltip.Group openDelay={300} closeDelay={100}>
        <Avatar.Group spacing="xs">
          {requests.map(({ id, user, comment }) => (
            <Tooltip
              key={id}
              label={comment ? `${user?.fullname}: ${comment}` : user?.fullname}
              multiline
              withArrow
              maw={320}
            >
              <Avatar size="sm" src={user?.avatar} alt={user?.displayname} style={{ borderWidth: 0 }} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </Tooltip.Group>
    </Group>
  </Badge>
);
