import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AddToMeetingContextProvider, AuthContextProvider, DelegateCardContextProvider } from './contexts';
import { NavbarLayout } from './layouts/NavbarLayout';
// import { ApiKeysPage } from './pages/ApiKeysPage';
import { ArchivePage } from './pages/ArchivePage';
import { CardPage } from './pages/CardPage';
import { EmptyPage } from './pages/EmptyPage';
// import { ComponentPage } from './pages/ComponentPage';
// import { DataSourcesPage } from './pages/DataSourcesPage';
// import { DevResourcesPage } from './pages/DevResourcesPage';
import { IntegrationsPage } from './pages/IntegrationsPage';
import { LoginPage } from './pages/LoginPage';
import { MeetingPage } from './pages/MeetingPage';
import { ProfilePage } from './pages/ProfilePage';
import { StreamPage } from './pages/StreamPage';
import { TemplatePage } from './pages/TemplatePage';
import { TemplatesListPage } from './pages/TemplatesListPage';
import { UsersPage } from './pages/UsersPage';
import { theme } from './theme';
import { RouteNames } from './types';

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: RouteNames.Login,
    element: <LoginPage />,
  },
  {
    path: RouteNames.Index,
    element: <EmptyPage />,
  },
  {
    path: RouteNames.Home,
    element: <NavbarLayout />,
    children: [
      {
        path: RouteNames.Home,
        element: <StreamPage />,
      },
      {
        path: RouteNames.Users,
        element: <UsersPage />,
      },
      {
        path: RouteNames.Profile,
        element: <ProfilePage />,
      },
      {
        path: RouteNames.Stream,
        element: <StreamPage />,
      },
      {
        path: RouteNames.Meeting,
        element: <MeetingPage />,
      },
      {
        path: RouteNames.Favorites,
        element: <StreamPage />,
      },
      {
        path: RouteNames.Vault,
        element: <ArchivePage />,
      },
      {
        path: RouteNames.Card,
        element: <CardPage />,
      },
      {
        path: RouteNames.Integrations,
        element: <IntegrationsPage />,
      },
      {
        path: RouteNames.Templates,
        element: <TemplatesListPage />,
      },
      {
        path: RouteNames.Template,
        element: <TemplatePage />,
      },
      // {
      //   path: '/keys',
      //   element: <ApiKeysPage />,
      // },
      // {
      //   path: '/dev-resources',
      //   element: <DevResourcesPage />,
      // },
      // {
      //   path: '/dev-components',
      //   element: <ComponentPage />,
      // },
      // {
      //   path: '/dev-datasources',
      //   element: <DataSourcesPage />,
      // },
    ],
  },
]);

export const App: FC = () => (
  <MantineProvider theme={theme}>
    <ModalsProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <AddToMeetingContextProvider>
            <DelegateCardContextProvider>
              <RouterProvider router={router} />
            </DelegateCardContextProvider>
          </AddToMeetingContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </ModalsProvider>
  </MantineProvider>
);
