import { Group, Stack, Text } from '@mantine/core';
import { FC } from 'react';

import { useAuthContext } from '../../../../../../../../contexts';
import { shouldDisplayActionPanel } from '../../utils';
import { TimelineItem } from '../TimelineItem';
import { RequestActions, RequestTypeBadge } from './components';
import { RequesterItemProps } from './types';

export const RequesterItem: FC<RequesterItemProps> = ({ card, request, user }) => {
  const { content, title, tcreated } = request;
  const { currentUserId } = useAuthContext();

  const shouldShowActionPanel = shouldDisplayActionPanel({ card, request, currentUserId });

  return (
    <TimelineItem
      user={user}
      time={tcreated}
      rightSection={
        <Group>
          <RequestTypeBadge request={request} />
          {shouldShowActionPanel && <RequestActions card={card} request={request} />}
        </Group>
      }
    >
      {content === '' && <Text>{title}</Text>}
      {content !== '' && (
        <Stack>
          <Text fw="bold">{title}</Text>
          <Text>{content}</Text>
        </Stack>
      )}
    </TimelineItem>
  );
};
