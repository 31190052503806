import { Group, Stack } from '@mantine/core';
import { FC } from 'react';

import { CardHeaderTimeAgo } from '../../../../../../components/CardHeaderTimeAgo';
import { StateBtn } from '../../../../../../components/StateBtn';
import { UserAvatar } from '../../../../../../components/UserAvatar';
import { Card } from '../../../../../../data';
import { getCardOwner } from '../../../../../../utils';
import { CardCreator, FullCardMenu } from './components';

interface FullCardHeaderProps {
  card: Card;
  setIsEditingTitle: (value: boolean) => void;
}

export const FullCardHeader: FC<FullCardHeaderProps> = ({ card, setIsEditingTitle }) => {
  const owner = getCardOwner(card);
  const creator = card.creator_id;

  return (
    <Group justify="space-between" align="start">
      <Stack>
        <UserAvatar user_id={getCardOwner(card)} />

        {owner !== creator && <CardCreator card={card} />}
      </Stack>
      <Group justify="flex-end">
        <CardHeaderTimeAgo card={card} />
        <StateBtn card={card} />
        <FullCardMenu card={card} setIsEditingTitle={setIsEditingTitle} />
      </Group>
    </Group>
  );
};
