import { ActionIcon, Menu, px, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconArchive,
  IconDots,
  IconRipple,
  IconSettings,
  IconTrash,
  IconUserDown,
  IconUsersGroup,
} from '@tabler/icons-react';
import { FC, useCallback } from 'react';

import { AddToStreamModal } from '../../../../../../../../components/AddToStreamModal';
import { useAddToMeetingContext, useAuthContext, useDelegateCardContext } from '../../../../../../../../contexts';
import { useCardsManager } from '../../../../../../../../hooks';
import { getCardOwner } from '../../../../../../../../utils';
import { useCollection } from '../../../../../../../StreamPage/hooks';
import { DeleteCardModal } from '../../../DeleteCardModal';
import { FullCardMenuProps } from './types';

export const FullCardMenu: FC<FullCardMenuProps> = ({ card, setIsEditingTitle }) => {
  const { openAddToMeetingModal } = useAddToMeetingContext();
  const { openDelegateCardModal } = useDelegateCardContext();
  const { updateCard } = useCardsManager();
  const { currentUserId } = useAuthContext();
  const { collection } = useCollection();

  const [pickStreamOpened, pickStreamHandlers] = useDisclosure(false);
  const [deleteCardOpened, deleteCardHandlers] = useDisclosure(false);

  const handleArchiveCard = useCallback(async () => {
    await updateCard({
      ...card,
      state: 'archived',
    });
  }, [card, updateCard]);

  const handleUnarchiveCard = useCallback(async () => {
    await updateCard({
      ...card,
      state: 'open',
    });
  }, [card, updateCard]);

  const isCompleted = card.state === 'completed' || card.state === 'archived';
  const isOwner = getCardOwner(card) === currentUserId;

  return (
    <>
      <AddToStreamModal
        card={card}
        collectionId={collection?.id}
        isOpened={pickStreamOpened}
        onClose={pickStreamHandlers.close}
      />
      <DeleteCardModal card={card} isOpened={deleteCardOpened} onClose={deleteCardHandlers.close} />
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Tooltip label="Card actions" withArrow>
            <ActionIcon>
              <IconDots size={px('1rem')} />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconSettings size={14} />}
            onClick={() => setIsEditingTitle(true)}
            disabled={isCompleted}
          >
            Edit title
          </Menu.Item>
          <Menu.Item leftSection={<IconRipple size={14} />} onClick={pickStreamHandlers.open}>
            Add Card to stream
          </Menu.Item>
          <Menu.Item
            leftSection={<IconUsersGroup size={14} />}
            onClick={(e) => {
              e.stopPropagation();
              openAddToMeetingModal(card);
            }}
            disabled={isCompleted}
          >
            Add Card to meeting
          </Menu.Item>
          {isOwner && (
            <>
              {card.state === 'open' && (
                <Menu.Item leftSection={<IconArchive size={14} />} onClick={handleArchiveCard}>
                  Archive Card
                </Menu.Item>
              )}

              {card.state === 'archived' && (
                <Menu.Item leftSection={<IconArchive size={14} />} onClick={handleUnarchiveCard}>
                  Unarchive Card
                </Menu.Item>
              )}
              <Menu.Item
                leftSection={<IconUserDown size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  openDelegateCardModal(card);
                }}
                disabled={isCompleted}
              >
                Delegate Card
              </Menu.Item>
              <Menu.Item
                leftSection={<IconTrash size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteCardHandlers.open();
                }}
                disabled={isCompleted}
              >
                Delete Card
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
