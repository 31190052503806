import { ActionIcon, Menu, px, Tooltip } from '@mantine/core';
import {
  IconArchive,
  IconDots,
  IconEye,
  IconRipple,
  IconRippleOff,
  IconUserDown,
  IconUserOff,
  IconUsersGroup,
} from '@tabler/icons-react';
import { FC } from 'react';

import { useAuthContext } from '../../../../../../contexts';
import { useCollection } from '../../../../../../hooks';
import { getCardOwner } from '../../../../../../utils';
import { CardActionsProps } from './types';

export const CardActions: FC<CardActionsProps> = ({
  card,
  collectionId,
  isRemovalDisabled,
  onOpenCard,
  onArchiveCard,
  onUnarchiveCard,
  onAddToStream,
  onAddToMeeting,
  onRemoveFromStream,
  onDelegateCard,
}) => {
  const { currentUserId } = useAuthContext();
  const isOwner = getCardOwner(card) === currentUserId;
  const collection = useCollection(collectionId);

  const isMeeting = collection?.type === 'meeting';

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Tooltip
          onClick={(e) => {
            e.stopPropagation();
          }}
          label="Card actions"
          withArrow
        >
          <ActionIcon variant="filled">
            <IconDots size={px('1rem')} />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Card Actions</Menu.Label>
        <Menu.Item
          leftSection={<IconEye size={14} />}
          onClick={(e) => {
            e.stopPropagation();
            onOpenCard();
          }}
        >
          View Card
        </Menu.Item>
        <Menu.Item
          leftSection={<IconRipple size={14} />}
          onClick={(e) => {
            e.stopPropagation();
            onAddToStream();
          }}
        >
          Add Card to stream
        </Menu.Item>
        {!isMeeting && (
          <Menu.Item
            disabled={isRemovalDisabled}
            leftSection={<IconRippleOff size={14} />}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveFromStream();
            }}
          >
            Remove from stream
          </Menu.Item>
        )}
        <Menu.Item
          leftSection={<IconUsersGroup size={14} />}
          onClick={(e) => {
            e.stopPropagation();
            onAddToMeeting();
          }}
        >
          Add card to meeting
        </Menu.Item>
        {isMeeting && (
          <Menu.Item
            disabled={isRemovalDisabled}
            leftSection={<IconUserOff size={14} />}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveFromStream();
            }}
          >
            Remove from meeting
          </Menu.Item>
        )}
        {isOwner && (
          <>
            {card.state === 'open' && (
              <Menu.Item
                leftSection={<IconArchive size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onArchiveCard();
                }}
              >
                Archive Card
              </Menu.Item>
            )}
            {card.state === 'archived' && (
              <Menu.Item
                leftSection={<IconArchive size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onUnarchiveCard();
                }}
              >
                Unarchive Card
              </Menu.Item>
            )}
            <Menu.Item
              leftSection={<IconUserDown size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                onDelegateCard();
              }}
            >
              Delegate card
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
