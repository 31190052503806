import { ActionIcon, Group, px, Tooltip } from '@mantine/core';
import { IconCheck, IconSend } from '@tabler/icons-react';
import { FC, useState } from 'react';

import { useAuthContext } from '../../contexts';
import { getCardOwner } from '../../utils';
import { CompleteCardForm, FavoriteBtn, SendRequestForm } from './components';
import { useCompleteMessage } from './hooks';
import { RequestActionsProps } from './types';

export const RequestActions: FC<RequestActionsProps> = ({ card, children }) => {
  const { currentUserId } = useAuthContext();

  const [showRequest, setShowRequest] = useState(false);
  const [showComplete, setShowComplete] = useState(false);

  const { completeMessage, tooltipLabel, isCompletable, setCanComplete } = useCompleteMessage(card.state);

  const isArchived = card.state === 'archived';

  if (isArchived) {
    return null;
  }

  if (showRequest) {
    return (
      <SendRequestForm
        card={card}
        onHide={() => {
          setShowRequest(false);
        }}
      />
    );
  }

  if (showComplete) {
    return (
      <CompleteCardForm
        card={card}
        completeMessage={completeMessage}
        onHide={() => {
          setShowComplete(false);
          setCanComplete(false);
        }}
      />
    );
  }

  return (
    <Group
      gap={8}
      style={{ cursor: 'default' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Tooltip label="Create Request" withArrow>
        <ActionIcon
          variant="outline"
          radius="xl"
          onClick={() => {
            setShowRequest(true);
          }}
        >
          <IconSend size={px('1rem')} />
        </ActionIcon>
      </Tooltip>
      <FavoriteBtn cardId={card.id} />
      {children}

      {getCardOwner(card) === currentUserId && (
        <Tooltip label={tooltipLabel} withArrow>
          <ActionIcon
            variant="outline"
            radius="xl"
            color={isCompletable ? 'blue' : 'lightgray'}
            disabled={card.state === 'completed'}
            onClick={() => {
              if (isCompletable) {
                setShowComplete(true);
              } else {
                setCanComplete(true);
              }
            }}
          >
            <IconCheck size={px('1rem')} />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
};
