import { Button, Group } from '@mantine/core';
import { FC } from 'react';

import { useAddToMeetingContext } from '../../../../../../../../../../../../contexts';
import { Card, Request } from '../../../../../../../../../../../../data';
import { useApproval } from './hooks';

interface ApprovalButtonsProps {
  card: Card;
  request: Request;
  comment: string;
}

export const ApprovalButtons: FC<ApprovalButtonsProps> = ({ card, request, comment }) => {
  const { openAddToMeetingModal } = useAddToMeetingContext();

  const { handleApprove, handleDeny, handleLetsMeet } = useApproval(request, comment);

  return (
    <Group>
      <Button onClick={handleApprove}>Yes</Button>
      <Button onClick={handleDeny}>No</Button>
      <Button
        onClick={() => {
          openAddToMeetingModal(card, handleLetsMeet);
        }}
      >
        Let&apos;s Meet
      </Button>
    </Group>
  );
};
