import { Group, Text } from '@mantine/core';
import { FC } from 'react';

import { RequestTypes } from '../../../../../../../../../../data';
import { RESPONSE_TO_LABEL } from '../../constants';
import { RequestResponseProps } from './types';

export const RequestResponse: FC<RequestResponseProps> = ({ request }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { request_type, comment } = request;

  const shouldShowComment = comment !== '';

  return (
    <Group gap="4px">
      {request_type !== RequestTypes.Comment && (
        <Text fw="bold">
          {RESPONSE_TO_LABEL[request.response]}
          {shouldShowComment ? ':' : ''}
        </Text>
      )}
      {shouldShowComment && <Text>{request.comment}</Text>}
    </Group>
  );
};
